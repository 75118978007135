// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from 'jquery'
import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
// require("edit_tracker")


window.$ = jQuery;
// debugger

$(document).on('turbolinks:load', function () {
  $('.select-trigger').on('click', function(e) {
    e.preventDefault();
    $(e.currentTarget).closest('.custom-select').toggleClass('active');
  });

  $('.status-select li.custom-option').on('click', function(e) {
    e.preventDefault();
    var status = $(this).text();
    $('.status-select li.custom-option.active').removeClass('active');
    $(this).addClass('active');
    $('.status-select .select-trigger span').text(status);
    $('.status-select .select-trigger span').removeClass();
    $('.status-select .select-trigger span').addClass('status');

    if (status == 'Bestellt') {
      $('.status-select .select-trigger span').addClass('bestellt');
    } else if (status == 'Nicht bestellt') {
      $('.status-select .select-trigger span').addClass('nicht-bestellt');
    } else if (status == 'In der Ernte') {
      $('.status-select .select-trigger span').addClass('in-der-ernte');
    } else if (status == 'Abgeerntet') {
      $('.status-select .select-trigger span').addClass('abgeerntet');
    } else if (status == 'Keine erneute Bestellung') {
      $('.status-select .select-trigger span').addClass('keine-erneute-bestellung');
    }

    $('#status').val($(this).data('value'));
    $(e.currentTarget).closest('.custom-select').toggleClass('active');
  });

  $('.cabbage-select li.custom-option').on('click', function(e) {
    e.preventDefault();
    var status = $(this).text();
    $('.cabbage-select li.custom-option.active').removeClass('active');
    $(this).addClass('active');
    $('.cabbage-select .select-trigger span').text(status);
    $('.cabbage-select .select-trigger span').removeClass();
    $('.cabbage-select .select-trigger span').addClass('cabbage');

    if (status == 'Ja') {
      $('.cabbage-select .select-trigger span').addClass('true');
    } else if (status == 'Nein') {
      $('.cabbage-select .select-trigger span').addClass('false');
    }

    $('#is_cabbage').val($(this).data('value'));
    $(e.currentTarget).closest('.custom-select').toggleClass('active');
  });

  if ($('#status').val() == '0') {
    $('.status-select .select-trigger span').addClass('bestellt');
    $('.status-select .select-trigger span').text('Bestellt');
  } else if ($('#status').val() == '1') {
    $('.status-select .select-trigger span').addClass('nicht-bestellt');
    $('.status-select .select-trigger span').text('Nicht bestellt');
  } else if ($('#status').val() == '2') {
    $('.status-select .select-trigger span').addClass('in-der-ernte');
    $('.status-select .select-trigger span').text('In der Ernte');
  } else if ($('#status').val() == '3') {
    $('.status-select .select-trigger span').addClass('abgeerntet');
    $('.status-select .select-trigger span').text('Abgeerntet');
  } else if ($('#status').val() == '4') {
    $('.status-select .select-trigger span').addClass('keine-erneute-bestellung');
    $('.status-select .select-trigger span').text('Keine erneute Bestellung');
  }

  if ($('#is_cabbage').val() == 'true') {
    $('.cabbage-select .select-trigger span').addClass('true');
    $('.cabbage-select .select-trigger span').text('Ja');
  } else if ($('#is_cabbage').val() == 'false') {
    $('.cabbage-select .select-trigger span').addClass('false');
    $('.cabbage-select .select-trigger span').text('Nein');
  }
});